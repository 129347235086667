html, body, #root, #App {
	height: 100%;
	margin: 0px;
	background-color: #000;
}

body {
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
	line-height: 1.5;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.background1, .background2, .background3 {
	position: relative;
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

#app {
	height: 100vh;
}

.background {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.background1 {
	background-image: url(./images/background-image.webp);
}

.background2 {
	background-image: url(./images/background-image-2.jpg);
}

.background3 {
	background-image: url(./images/background-image-3.webp);
}

.caption {
	text-align: center;
	color: white;
	max-width: 1280px;
	width: 80%;
}

.contact-caption {
	text-align: center;
	color: white;
	max-width: 1440px;
}

#portfolio {
	padding-top: 60px;
}

.portfolio-caption {
	text-align: center;
	color: white;
}

#portfolio-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
	margin-top: 3em;
}

.portfolio-image {
	position: relative;
	margin: 20px;
	max-width: 500px;
	max-height: 400px;
	width: 85%;
}

.portfolio-text {
	position: absolute;
	top: 0%;
	left: 0%;
	opacity: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.75);
	transition: opacity 0.5s;
	color: white;
	padding: 5%;
	display: flex;
	justify-content: center;
	align-content: center;
	flex-direction: column;
}

.portfolio-text h5 {
	margin-bottom: 0px;
}

.portfolio-text:hover {
	opacity: 1;
}

.portfolio-preview {
	width: 100%;
	height: 100%;
}

.form-input {
	display: block;
	width: 100%;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	font-family: inherit;
}

.form {
	margin-bottom: 1rem;
}

label {
	display: inline-block;
	margin-bottom: 0.5rem;
}

#submit {
	cursor: pointer;
	color: #fff;
	background-color: #007bff;
	border-color: #007bff;
	border: 1px solid transparent;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0.25rem;
	font-family: inherit;
}

#heading {
	font-size: 5vmin;
	font-weight: bolder;
	margin: 0;
}

.navbar {
	background: rgba(0, 0, 0, 0.75);
	position: fixed;
	top: 0;
	right: 0;
	z-index: 1;
	display: block;
	width: 100%;
	padding: 0.5em 1rem;
	padding-right: 0px;
	height: 56px;
}

.navbarInner {
	height: 100%;
	display: flex;
	flex-direction: row;
}

.navbar a {
	float: right;
	color: #f2f2f2;
	text-decoration: none;
	font-size: 16px;
	padding: 16px 8px;
	margin-bottom: -8px;
	margin-top: -8px;
}

.navbar a:hover {
	background-color: rgba(221, 221, 221, 0.623);
	color: black !important;
}

.navbar button {
	background: none;
	border: none;
	float: right;
	color: #f2f2f2;
	text-decoration: none;
	font-size: 16px;
	padding: 16px 8px;
	margin-bottom: -8px;
	margin-top: -8px;
	cursor: pointer;
	font-family: 'Montserrat', sans-serif;
}

.navbar button:hover {
	background-color: rgba(221, 221, 221, 0.623);
	color: black !important;
}

.navbar p {
	float: left;
	color: #f2f2f2;
	text-decoration: none;
	font-size: 1.5em;
	padding: 2px;
	margin: 0px;
}

#github-link {
	float: none;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

a img {
	height: 20px;
}

img {
	vertical-align: middle;
}

#contactButton {
	font-weight: 700;
}

.validation {
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	height: 0;
	width: 0;
	z-index: -1;
}

#successMessage {
	border: #c3e6cb 1px solid transparent;
	color: #155724;
	background-color: #D4EDDA;
	border-radius: 0.25rem;
	padding: 0.75rem 1.25rem;
	margin-bottom: 1rem;
}

#error {
	border: #f5c6cb 1px solid transparent;
	background-color: #F8D7DA;
	color: #721c24;
	border-radius: 0.25rem;
	padding: 0.75rem 1.25rem;
	margin-bottom: 1rem;
}

#icon {
	display: none;
}

.page-enter {
	opacity: 0;
}
.page-enter-active {
	opacity: 1;
	transition: opacity 500ms;
}
.page-exit {
	opacity: 1;
}
.page-exit-active {
	opacity: 0;
	transition: opacity 500ms;
}

@media only screen and (max-width: 992px) {
	.navbarInner button {
		display: none;
	}
	
	.navbarInner a {
		display: none;
	}
	
	.navbar p {
		font-size: 1.3em;
	}
	
	#icon {
		position: absolute;
		right: 0;
		top: 0;
		display: block;
		margin: 0;
		font-size: 1.5em;
		padding: 0.5em;
	}
	
	.navbarInner.responsive {
		position: absolute;
		right: 0;
		top: 56px;
		flex-direction: column;
		width: fit-content;
		background: rgba(0, 0, 0, 1);
		z-index: 1;
		height: fit-content;
		
	}
	
	.navbarInner.responsive button {
		float: none;
		display: block;
		text-align: center;
		padding: 12px;
		margin: 0px;
	}
	
	.navbarInner.responsive a {
		float: none;
		display: block;
		text-align: center;
		padding: 12px;
		margin: 0px;
	}
}